import gql from "graphql-tag";

export const FAVORITE_FIELDS = gql`
	fragment FavFields on Favorite {
		id
		title
		type
		url
		points
		slug
		content
		locked
	}
`;

export const POST_FIELDS = gql`
	fragment PostFields on Post {
		id
		title
		type
		slug
		points
		locked
	}
`

export const POST_CONNECTION = gql`
	fragment ConnectionFields on PostConnection {
		edges {
			cursor
			node {
				id
				slug
				title
				type
				points
			}
		}
		pageInfo {
			startCursor
			endCursor
			hasPreviousPage
			hasNextPage
		}
	}
`

export const POST_CONTENT = gql`
	fragment Content on Post {
		id
		type
		slug
		title
		imageUrl
		content
		points
		locked
	}
`