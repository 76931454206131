import React from "react";
import Chevron from "./Chevron";

const FilterButton = ({ showFilter=null, filter }) => {

	const name = (filter) => {
		switch (filter) {
			case 'HUMOR':
				return 'humor'
			case 'OPINION':
				return 'opinion'
			case 'EDUCATIONAL':
				return 'educational'
			default:
				return 'all thoughts';
		}
	}

	return (
		<div className="flex justify-end">
			<p className="font-medium">Read <button onClick={(e) => showFilter(e)} className="text-purple-600"> {name(filter)} <Chevron className="inline h-5 w-5 fill-current text-purple-800" isOpen={false}/> </button></p>
		</div>
	)
}

export default FilterButton;