import React, { useEffect, useState } from "react";

const url = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_API_URI}/logout` : 'http://localhost:4001/logout';

const SignO = () => {

	const [res, setRes] = useState('⚙️');

	const submit = () => {
		const options = {
			method: 'get',
			credentials: 'include',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
			}
		}

		fetch(url, options)
		.then(response => {
			return response
		})
		.then(data => {
			setRes('👌')
			document.cookie=`${process.env.REACT_APP_AUTH}=; expires=Thu, 01 Jan 1970 00:00:00 UTC path=/;`;
			return res
		})
		.catch(err => {
			setRes('🥶')
		})
	}

	useEffect(() => {
		if (res === '⚙️') {
			submit()
		}
	})

	return (
		<p className="extralight">!Auth: {res}</p>
	)
}

export default SignO;
