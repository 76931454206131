import React from "react";
import RandomStar from "./RandomStar";
import { Link } from "react-router-dom";

const ViewedBlogCell = ({ obj, auth, onDelete, index, number, last }) => { 

	const path = `/thoughts/${obj.slug}`

	const emojis = { 
		"OPINION": "🙄",
		"EDUCATIONAL" :"🧑‍🏫",
		"HUMOR": "😜"
	}

	const num = (index + 1) / number

	return (
		<>
		<div className="cell min-w-full mb-1 flex justify-between text-gray-500 pr-2 px-4 rounded hover:bg-gray-100" id={obj.id}>
			<Link className="min-w-full h-16 flex flex-row justify-between items-center" to={path}>
				<h1 className="font-medium">{obj.title}</h1>
				<div className="flex relative">
				<p className="font-light text-xs my-1 px-1">{emojis[obj.type]}</p>
				<RandomStar className="fill-current text-gray-300 h-6 w-6 inline" />
				{obj.points > 9 
					? <p className="absolute right-1.5 top-1 font-bold text-xs">{obj.points}</p>
					: <p className="absolute right-2 top-1 font-bold text-xs">{obj.points}</p>
				}
				</div>
			</Link>
			{auth() 
				? <button 
				onClick={() => onDelete(obj)}>🗑</button>
				: <></>
			}
		</div>
			{
				last === index + 1
				? <></>
				:
				Number.isInteger( num )
				?
				<div className="flex flex-row justify-between mt-4 mb-6">
					<p className="inline-block px-4 text-sm font-semibold text-red-400">{num+1}</p>
					<div className="w-full border-b-2 border-red-300 inline-block mb-2"></div>
				</div>
				:
				<></>
			}
		</>
	);
};

export default ViewedBlogCell;