import React, { useReducer, createContext } from "react";

export const ViewedContentContext = createContext();

const VIEWED = "VIEWED";
const RESET = "RESET";

const initialState = [];

const reducer = (state = [], action) => {
	if (action.type === VIEWED) {
		return [action.payload.id, ...state.filter(identity => identity !== action.payload.id)]
	} else if (action.type === RESET) {
		return [];
	}
	return state;
}

export const ViewedContentProvider = ({ children }) => {
	const [viewedPosts, dispatch] = useReducer(reducer, initialState);

	const view = ({ id }) => {
		dispatch({
			type: VIEWED,
			payload: { id }
		});
	};

	const viewReset = () => {
		dispatch({
			type: RESET
		})
	}

	return (
		<ViewedContentContext.Provider value={{ viewedPosts, view, viewReset }}>
			{children}
		</ViewedContentContext.Provider>
	);
};