import * as yup from "yup";

const schema =  yup.object().shape({
	name: yup.string().min(2).max(255).required(),
	type: yup.string().min(2).max(255).required(),
	slug: yup.string().min(2).max(255).required(),
	points: yup.number().required().default(0).integer(),
	content: yup.string().min(2).max(255).required(),
	imageUrl: yup.string().min(2).max(255).required(),
	url: yup.string().min(2).max(255).required(),
	contributors: yup.string().min(2).max(255).required(),
	locked: yup.boolean().required()
});

export default schema;