import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { KarmaContext } from "../context/KarmaContext";

import RandomStar from "../components/RandomStar";
import Locked from "../components/Locked";

const LockedRoute = ({ children, points, ...rest }) => {

	const { karma } = useContext(KarmaContext);
	const length = Array.from({ length: points }, (v, i) => i);

	return (
		<Route
			{...rest}
			render={props => {
				if (karma >= points) { 
					return <>{ children }</>
				} else {
					return <div className="relative mx-auto my-24 flex flex-col items-center text-xs md:text-sm lg:text-lg">
						<div className="stars-delay">
						{length.map(num => (
							<RandomStar key={num} className="absolute fill-current text-yellow-300 h-4 w-4 lg:h-8 w-8 inline z-10 animate-surprise" />
						))}
						</div>
						<p className="bg-white lg:px-48 py-2 z-20">Collect <span className="font-extrabold">{points}</span> stars to unlock this content</p>
						<Locked className="h-28 w-28 inline" />
					</div>
				}
			}}
		/>
	)
};

export default LockedRoute;