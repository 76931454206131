import React, { useContext } from "react";
import RandomStar from "./RandomStar";
import { KarmaContext } from "../context/KarmaContext";
import { ViewedContentContext } from "../context/ViewedContentContext";
import { QuizContext } from "../context/QuizContext";
import { NavContext } from "../context/NavContext";

const Footer = () => {

	const { karmaReset } = useContext(KarmaContext);
	const { viewReset } = useContext(ViewedContentContext);
	const { quizReset } = useContext(QuizContext);
	const { navReset } = useContext(NavContext);

	return (
		<footer className="fixed min-w-full h-6 bottom-0 left-0 flex justify-between items-center bg-white bg-opacity-50 z-50">
			<button 
				className="text-blue-700 font-medium mx-4 text-xs"
				onClick={() => {
					karmaReset();
					viewReset();
					quizReset();
					navReset();
				}
			}>
				Reset 
				<RandomStar 
					className="h-5 w-5 text-yellow-300 fill-current inline" />
				's
			</button>
			<p className="inline mx-4 font-extralight text-xs"><a href="https://github.com/tclohm"><i className="fab fa-github"></i></a> <a href="https://www.linkedin.com/in/taylorclohman"><i className="fab fa-linkedin"></i></a> Made in LA</p>
		</footer>
	);
}

export default Footer;