import React from "react";

const WebIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" 
		fill="none" 
		viewBox="0 0 24 24" 
		stroke="currentColor"
		className="h-8 w-8 sm:h-6 sm:w-6 absolute bottom-0 right-0 text-transparent group-hover:text-white">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
	</svg>
);

export default WebIcon;