import React, { useContext, useEffect } from "react";
import { NavContext } from "../context/NavContext";
import { KarmaContext } from "../context/KarmaContext";
import NavLink from "./NavLink";

const Nav = () => {

	const { karma } = useContext(KarmaContext);
	const { links, unlock } = useContext(NavContext);

	useEffect(() => {
		
		const about = links[0]
		const quiz = links[4]

		if (about['locked'] && karma >= about['points']) {
			unlock({ id: about['id'] })
		}
		if (quiz['locked'] && karma >= quiz['points']) {
			unlock({ id: quiz['id']})
		}
	}, [karma, links, unlock])

	return (
		<div className="sticky px-1 overflow-y-auto font-medium text-xs sm:text-base sm:px-3 xl:px-5 pb-10 sm:pt-10 w-full lg:bg-white lg:h-auto xs:pt-0 sm:w-60 xl:w-72 lg:block z-30 bg-white">
			<nav className="fixed flex w-full sm:w-48 sm:flex-col flex-row justify-around bg-white">
				{links.map(link => (
					<NavLink to={link.path}
							 key={link.id}
							 locked={link.locked}
							 label={link.path.slice(1)}
							 points={link.points}
							 active={link === '/blog' ? true : false }
					/>
				))}

			</nav>
		</div>
	);
};

export default Nav;