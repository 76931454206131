import React, { useState, useContext } from "react";

import FavoriteForm from "../components/FavoriteForm";
import Modal from "../components/Modal";

import { AuthContext } from "../context/AuthContext";

// MARK: -- GRAPHQL
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_FAVORITES } from "../graphql/queries";
import { CREATE_FAVORITE, UPDATE_FAVORITE, DELETE_FAVORITE } from "../graphql/mutations";

// MARK: -- schema
import schema from "../validations/FavoriteSchema";

const ADDFAV = "Add Favorite";

const FavoriteList = () => {
	// MARK: -- simple hooks
	const [detailInput, setDetailInput] = useState({});
	const [editing, setEditing] = useState(false);
	const [show, setShow] = useState(false);
	const [err, setErr] = useState(false)

	// MARK: -- context
	const { isAuthenticated } = useContext(AuthContext);
	
	// MARK: -- graphql
	const { data, loading, error } = useQuery(GET_FAVORITES);

	const [addFavorite, newFavorite] = useMutation(CREATE_FAVORITE, {
		update(cache, { data: { addFavorite } }) {
			const data = cache.readQuery({ query: GET_FAVORITES });
			cache.writeQuery({
				query: GET_FAVORITES,
				data: { favorites: [addFavorite, ...data.favorites] }
			});
		}
	});

	const [updateFavorite, updatedFavorite] = useMutation(UPDATE_FAVORITE, {
		update(cache, { data: { updateFavorite } }) {
			const data = cache.readQuery({ query: GET_FAVORITES });
			cache.writeQuery({
				query: GET_FAVORITES,
				data: { favorites: [...data.favorites] }
			});
		}
	});

	const [removeFavorite, deleted] = useMutation(DELETE_FAVORITE, {
		update(cache, { data: { removeFavorite } }) {
			const identifier = removeFavorite.split(" ")[1];
			const data = cache.readQuery({ query: GET_FAVORITES });
			cache.writeQuery({
				query: GET_FAVORITES,
				data: { favorites: data.favorites.filter(obj => obj.id !== identifier) }
			});
		}
	});

	//// MARK: -- CRUD
	// MARK: -- POST, PUT
	const onSubmit = async (input) => {
		const { id, __typename, createdAt, ...rest } = input;
		const valid = await schema.isValid(rest);
		if (editing && valid) {
			updateFavorite({
				variables: { updatedFavorite: rest, id: id },
				optimisticResponse: {
					__typename: 'Mutation',
					updateFavorite: {
						__typename: 'Favorite',
						id: id,
						title: rest.title,
						type: rest.type,
						url: rest.url,
						points: rest.points,
						slug: rest.slug,
						content: rest.content,
						locked: rest.locked,
					}
				}
			})
			setShow(false)
		} else if (valid) {
			addFavorite({ 
				variables: { newFavorite: input },
				optimisticResponse: {
					__typename: 'Mutation',
					addFavorite: {
						__typename: 'Favorite',
						id: Math.floor(Math.random() * 1000) + '',
						title: input.title,
						type: input.type,
						url: input.url,
						points: input.points,
						slug: input.slug,
						content: input.content,
						locked: input.locked,
					}
				}
			})
			setShow(false);
		} else {
			setErr(true);
			setTimeout(() => { setErr(false) }, 3000);
		}
	}

	// MARK: -- Delete
	const onDelete = (input) => {
		const { id } = input;
		removeFavorite({
			variables: { deleted: id }
		})
	}

	// MARK: -- Editing
	const getDetails = (event, input) => {
		event.preventDefault();
		setEditing(true);
		setDetailInput(input);
		showModal(event);
	}

	// MARK: -- Modals
	const showModal = (event) => {
		event.preventDefault();
		const text = event.target.innerText
		if (text === ADDFAV) {
			setEditing(false);
			setDetailInput({})
		}
		setShow(true);
	}

	const hideModal = (event) => {
		event.preventDefault();
		const exitArea = Array.from(event.target.classList)[0]
		if (exitArea === 'close') {
			setShow(false);
		}
	}

	// MARK: -- Retrieval
	if (loading || data.favorites[0] === null) return <p>Loading...</p>
	if (error || newFavorite.error || updatedFavorite.error || deleted.error) return <p>ERROR</p>
	if (data)

	return (
		<>
		{ 
			isAuthenticated()
			? <>
				<button 
				type="button" 
				className="rounded border border-b-4 border-green-900 bg-green-600 px-2 py-1 mb-4 text-white font-medium hover:bg-green-700" 
				onClick={(e) => showModal(e)}>
					{ADDFAV}
				</button>
				{
					show
					?
					<Modal show={show} handleClose={(e) => hideModal(e)}>
						 <FavoriteForm 
						 	editing={editing} 
						 	favoriteObject={detailInput} 
						 	onSubmit={onSubmit}
						 	error={err}
						/>
					</Modal>
					: 
					<></>
				}
			  </>
			: <></>
		}
		<section>
			{data.favorites.map(obj => (
				<div key={obj.id} className="min-w-full flex justify-between my-2">
					<a href={obj.url}>
						<h1 className="text-medium text-sm md:text-lg inline hover:underline hover:text-blue-400">
							{(() => {
									switch (obj.type) {
										case "FILM": return <span alt="link and film emoji">🎥</span>
										case "BOOK": return <span alt="link and book emoji">📚</span>
										case "ARTICLE": return <span alt="link and newpaper emoji">📰</span>
										default: return <p>error on types</p>
									}
							})()} <i className="fas fa-link"></i> {obj.title}
						</h1>
					</a>
					{ 
						isAuthenticated()
						? <div  className="inline-flex">
							<button
							className="mr-4 rounded w-14 border border-b-2 border-gray-300 hover:bg-gray-100 hover:border-0"
							onClick={event => getDetails(event, obj)}
							>✍️</button>
							<button className="rounded w-14 border border-b-2 border-gray-300 hover:bg-gray-100 hover:border-0"
							onClick={() => onDelete(obj)}>🗑</button>
						  </div>
						: <></>
					}
				</div>
			))}
		</section>
		</>
	)
}

export default FavoriteList;