import React from "react";
import RandomStar from "./RandomStar";

const LoadingBlogCell = ({ className }) => { 

	return (
		<div className="cell min-w-full shadow-sm mb-1 flex justify-between pr-2">
			<div className="animate-pulse min-w-full h-16 flex flex-row justify-between items-center">
				<div className={className}></div>
				<div className="flex relative">
					<div className="rounded-full bg-gray-100 my-1 px-1 h-4 w-4"></div>
					<RandomStar className="fill-current text-gray-300 h-6 w-6 inline" />
				</div>
			</div>
		</div>
	);
};

export default LoadingBlogCell;