import React, { useContext } from "react";
import { NavContext } from "../context/NavContext";

import { Switch, Route } from "react-router-dom";
import LockedRoute from "./LockedRoute";
import BlogList from "../pages/BlogList";
import BlogDetail from "../pages/BlogDetail";
import About from "../pages/About";
import Projects from "../pages/Projects";
import FavoriteList from "../pages/FavoriteList";
import Quiz from "../pages/Quiz";
import SignN from "../pages/SignN";
import SignO from "../pages/SignO";
import FourOhFour from "../pages/404";
import Home from "../pages/Home";

import AuthenticatedRoute from "./AuthenticatedRoute";

const Routes = () => {

	const { links } = useContext(NavContext);
	
	const aboutPath = links[0]['path']
	const blogPath = links[1]['path']
	const projectPath = links[2]['path']
	const favoritePath = links[3]['path']
	const quizPath = links[4]['path']

	const aboutPoints = links[0]['points']
	const quizPoints = links[4]['points']

	return (
		<Switch>
			<AuthenticatedRoute path="/logout"><SignO/></AuthenticatedRoute>
			<Route path="/parker"><SignN/></Route>
			<Route exact path={blogPath}><BlogList/></Route>
			<Route path={aboutPath} points={aboutPoints}><About/></Route>
			<Route path={projectPath}><Projects/></Route>
			<Route path={favoritePath}><FavoriteList/></Route>
			<LockedRoute path={quizPath} points={quizPoints}><Quiz/></LockedRoute>
			<Route exact path="/"><Home/></Route>
			<Route path={`${blogPath}/:slug`}><BlogDetail/></Route>
			<Route><FourOhFour/></Route>
		</Switch>
	);
};

export default Routes;