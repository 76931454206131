import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_CONTENT } from "../graphql/queries";
import { UPDATE_POST } from "../graphql/mutations";

import BlogForm from "../components/BlogForm";

import { AuthContext } from "../context/AuthContext";

import schema from "../validations/BlogSchema";

const BlogDetail = () => {
	const history = useHistory();
	const { slug } = useParams();
	const {data, loading, error} = useQuery(GET_CONTENT, {
		variables: { slug: slug }
	});

	const [object, setObject] = useState({}); 

	const [err, setErr] = useState(false);

	const [identifier, setIdentifier] = useState(0);

	const [updatePost, updatedPost] = useMutation(UPDATE_POST);

	const { isAuthenticated } = useContext(AuthContext);

	const [isHidden, setHidden] = useState(false);

	useEffect(() => {
		if (data && data.post !== null) {
			const { id, __typename, ...rest } = data.post
			setObject(rest)
			setIdentifier(data.post.id);
		}
	}, [data]);

	const checkValues = (obj1, obj2) => {
		const values1 = Object.values(obj1).sort();
		const values2 = Object.values(obj2).sort();
		let valid = true;
		values1.forEach((value, index) => { if(values2[index] !== value) { valid = false } })
		return valid;
	};

	const onSubmit = async (event, input) => {
		event.preventDefault();
		const { __typename, ...rest } = input;

		const same = checkValues(rest, object);

		const valid = await schema.isValid(rest);

		if (identifier !== 0 && valid && !same) { 
			updatePost({
				variables: { updatedPost: rest, id: identifier }
			})
			history.push("/thoughts");
		} else {
			setErr(true)
			setTimeout(function() {
				setErr(false)
			}, 3000);
		}


	}

	if (loading) return <p>Loading...</p>
	if (error || updatedPost.error || data.post === null) return <p>ERROR</p>
	if (data)
	return (
		<>
		{
		isAuthenticated()
		?
		<BlogForm onSubmit={onSubmit} editing={true} BlogObject={data.post} error={err} />
		:
		<div>
			<h3 className="lg:text-7xl text-4xl pb-2 font-black">{slug}</h3>
			<div className="flex lg:flex-row-reverse flex-col items-center">
				<div className="flex flex-col">
					{ isHidden 
						? <div className="bg-black"></div>
						: <img src={data.post.imageUrl} alt="placeholder"/>
					}
					<button
							className="py-2 border-2 border-white rounded hover:bg-black hover:text-white font-bold"
							onClick={() => { setHidden(!isHidden) }}
						>
							{isHidden ? "show" : "hide"}
					</button>
				</div>
				<p className="lg:py-16 lg:pr-12 py-4 lg:pr-36">{data.post.content}</p>
			</div>
		</div>
		}
		</>
	);
}

export default BlogDetail;