import React, { useState } from "react";
import Form from "../components/Form";
import Input from "../components/Input";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";

const url = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_API_URI}/login` : 'http://localhost:4001/login';

const SignN = () => {
	const history = useHistory();
	const me = `${process.env.REACT_APP_AUTH}`
	const [password, setPassword] = useState('');

	const submit = event => {
		event.preventDefault();
		const options = {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
			},
			body: `username=${me}&password=${password}`
		}

		fetch(url, options)
		.then(response => {
			return response.json()
		})
		.then(data => {
			console.log(data)
			if (data.success && me === 'tclohm') {
				document.cookie = `${me}=true;sameSite=Strict;secure=true;maxAge=18000;`
			}
			history.push("/");
		})
		.catch(err => {
			console.log(err)
		})
	}

	return (
		<>
			<Form submit={submit}>
				<Input type="password" onChange={event => setPassword(event.target.value)} />
				<Button type="submit" title="sign in" />
			</Form>
		</>
	)
}

export default SignN;
