import React, { useReducer, createContext } from "react";

export const QuizContext = createContext();

const ANSWER = "ANSWER";
const NEXT = "NEXT";
const RESET = "RESET";

const initialState = [
	{'pointer': 0 },
	{'questions': [
		{ 
			'id': 0, 
			'question': 'What is Taylor\'s middle name?', 
			'options': ['Talbot', 'Oscar', 'Chase', 'Lewis'],
			'answer': 'Chase',
			'choice': ''
		},
		{ 
			'id': 1, 
			'question': 'What did Taylor learn to first ride a bicycle?', 
			'options': ['Santa Barbara, CA', 'Cape Cod, MA', 'Redwood City, CA', 'Palm Desert, CA'], 
			'answer': 'Palm Desert, CA',
			'choice': '' 
		},
		{ 
			'id': 2, 
			'question': 'What is Taylor\'s favorite book?', 
			'options': ['Jonathan Livingston Seagull', 'Thinking Fast and Slow', 'The Paradox of Choice', 'The Graveyard'],
			'answer': 'Jonathan Livingston Seagull',
			'choice': '' 
		},
		{ 
			'id': 3, 
			'question': 'What is Taylor\'s banking pin number?', 
			'options': ['1234', '4321', '7130', 'Freebie!'], 
			'answer': 'Freebie!', 
			'choice': ''
		},
		{ 
			'id': 4, 
			'question': 'What sport did Taylor play in college?', 
			'options': ['Soccer', 'Lacrosse', 'Basketball', 'Tennis'], 
			'answer': 'Lacrosse',
			'choice': '', 
		},
		{ 
			'id': 5, 
			'question': 'Thanks for taking the quiz!', 
			'options': [], 
			'answers': 'done',
			'choice': ''
		}
	]},
	{'correct': null },
];

const reducer = (state, action) => {
	if (action.type === ANSWER) {
		// MARK: -- grab choice and check with answer
		const questions = state[1].questions
		if (action.payload.choice === questions[state[0].pointer].answer) {
			return [state[0], state[1], { correct: true }]
		} else {
			return [state[0], state[1], { correct: false }]
		}
	} else if (action.type === NEXT) {
		const newpointer = state[0].pointer + 1
		return [{ pointer: newpointer }, state[1], { correct: null }]
	} else if (action.type === RESET) {
		return [{ pointer: 0 }, state[1], { correct: null } ]
	}
	return state;
}

export const QuizProvider = ({ children }) => {
	const [quiz, dispatch] = useReducer(reducer, initialState);

	const answer = ({ id, choice }) => {
		dispatch({
			type: ANSWER,
			payload: { id, choice }
		});
	}

	const next = () => {
		dispatch({
			type: NEXT
		});
	}

	const quizReset = () => {
		dispatch({
			type: RESET
		})
	}

	return (
		<QuizContext.Provider value={{ quiz, answer, next, quizReset }}>
			{ children }
		</QuizContext.Provider>
	);
}