import React, { useContext, useEffect, useState, useCallback } from "react";
import { QuizContext } from "../context/QuizContext";
import { KarmaContext } from "../context/KarmaContext";
import { v4 as id } from "uuid";

const Quiz = () => {

	const GREEN = 'GREEN';
	const RED = 'RED';

	const { quiz, answer, next } = useContext(QuizContext);
	const { giveStarHandler } = useContext(KarmaContext);
	const [choice, setChoice] = useState('');

	const answerHandler = (e, index, choice) => {
		e.preventDefault();
		setChoice(choice);
		answer({ id: index, choice });
	};

	const feedback = useCallback((color, choice) => {
		const btns = document.getElementsByClassName('current-options');
		for (let el of btns) {
			if ((color === GREEN && el.classList.contains('border-purple-800')) && el.innerText === choice) {
				el.classList.remove('border-purple-800', 'hover:bg-purple-800', 'hover:text-white');
				el.classList.add('bg-green-200', 'border-green-200', 'cursor-not-allowed');
			} else if (color === RED && el.classList.contains('border-purple-800') && el.innerText === choice) {
				el.classList.remove('border-purple-800', 'hover:bg-purple-800', 'hover:text-white');
				el.classList.add('bg-red-500', 'border-red-500', 'cursor-not-allowed');
			} else {
				el.classList.remove('border-purple-800', 'hover:bg-purple-800', 'hover:text-white');
				el.classList.add('opacity-50', 'cursor-not-allowed');
			}
		}
	}, [])

	useEffect(() => {
		const p = document.getElementById('feedback');
		if (quiz[2].correct !== null) {
			p.classList.remove("m-6");
			if ( quiz[2].correct ) { 
				p.innerText = "Correct! You get 2 stars!";
				if (p.classList.contains("text-red-500")) { p.classList.remove("text-red-500"); }
				p.classList.add("text-green-500");
				feedback(GREEN, choice);
			} else {  
				p.innerText = "🙃 That's not the answer. But, I'm happy you took a guess. Here's a star for trying!"; 
				if (p.classList.contains("text-green-500")) { p.classList.remove("text-green-500"); }
				p.classList.add("text-red-500");
				feedback(RED, choice);
			}
		} else {
			p.classList.add("m-6");
			if (p.classList.contains("text-green-500")) { p.classList.remove("text-green-500"); } 
			else if (p.classList.contains("text-red-500")) { p.classList.remove("text-red-500"); }
			p.innerText = "";
		}
	}, [quiz, choice, feedback]);

	return (
		<section className="px-1 pt-6 font-medium text-base pb-10 lg:pt-10 lg:pb-14 lg:bg-white lg:static lg:h-auto lg:pt-0 lg:block">
				<p id="feedback" className="m-6"></p>
				{quiz[1].questions.map((question, index) => (
					<div key={id()}>
					{question.id === quiz[0].pointer
					?
						<div id={index} className="flex flex-col">
							<h3 className="text-black text-3xl lg:text-5xl">{question.question}</h3>
							<div className="text-black flex flex-col lg:flex-row justify-start my-12">
							{question.options.map(option => (
								<button key={id()} 
									className="current-options lg:px-8 md:px-4 sm:px-2 py-4 border-2 border-purple-800 rounded mr-2 hover:bg-purple-800 hover:text-white font-bold mt-1"
										onClick={
											(e) => answerHandler(e, index, option)
										}>
									{option}
								</button>
							))}
							</div>
						</div>
					:
						<div id={index} className="hidden"></div>
					}
					</div>
				))
				}
			{quiz[2].correct === true || quiz[2].correct === false
			? 	
				<div className="flex flex-col lg:flex-row">
				<button className="lg:px-8 md:px-4 sm:px-2 py-4 border-2 border-black rounded mr-2 hover:bg-black hover:text-white font-bold md:mt-2"
					  onClick={() => {
					  	if (quiz[2].correct) { giveStarHandler("star", "star-points", 2) } else { giveStarHandler("star", "star-points", 1) }
					  	next()
				}}>
					next
				</button>
				</div>
			: <></>
			}
		</section>
	);
}

export default Quiz;