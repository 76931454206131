import React from "react";
import Routes from "../routes";

const App = () => {

	return (
		<div className="min-w-0 flex-auto px-4 sm:px-6 xl:px-8 pt-10 pb-24 lg:pb-16">
			<Routes/>
		</div>
	)
};

export default App;