import React, { useState, useReducer, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const active = "active rounded px-4 py-1 mr-2 bg-purple-400";
const notActive = "not-active rounded ring-1 px-4 py-1 mr-2 ring-gray-200";

const err = "w-full h-8 pl-4 pt-1 mr-2 bg-red-500 text-white font-black rounded"

const GREENFIELD = 'GREENFIELD';
const BUILTON = 'BUILTON';
const WEB = 'WEB';
const PHONE = 'PHONE';

const SOLO = 'SOLO';
const MULTIPLE = 'MULTIPLE';

const TOGGLE = 'TOGGLE';

const ADD_IMAGE = gql`
	mutation ImageUpload($file: Upload!) {
		imageUpload(file: $file) {
			filename
			mimetype
			encoding
			location
		}
	}
`;

const ProjectForm = ({ editing, ProjectObject={}, onSubmit, error }) => {

	const [addImage, image] = useMutation(ADD_IMAGE)
	const [location, setLocation] = useState('');
	const [uploadcomplete, setUploadcomplete] = useState(false);

	const initialFavorite =
		{
			type: ProjectObject.type || '',
			name: ProjectObject.name || '',
			slug: ProjectObject.slug || '',
			url: ProjectObject.url || '',
			imageUrl: ProjectObject.imageUrl || location,
			points: ProjectObject.points || 0,
			content: ProjectObject.content || '',
			contributors: ProjectObject.contributors || '',
			locked: ProjectObject.locked || false
		};

	const typeState = [ 
		{ name: GREENFIELD,
		  icon: '🌿', 
		  id: uuidv4(),
		  class: initialFavorite.type === GREENFIELD ? active : notActive,
		  selected: initialFavorite.type === GREENFIELD ? true : false
		}, 
		{ name: BUILTON,
		  icon: '🏗',
		  id: uuidv4(),
		  class: initialFavorite.type === BUILTON ? active : notActive,
		  selected: initialFavorite.type === BUILTON ? true : false
		},
		{ name: WEB,
		  icon: '🕸',
		  id: uuidv4(),
		  class: initialFavorite.type === WEB ? active : notActive,
		  selected: initialFavorite.type === WEB ? true : false
		},
		{ name: PHONE,
		  icon: '📱',
		  id: uuidv4(),
		  class: initialFavorite.type === PHONE ? active : notActive,
		  selected: initialFavorite.type === PHONE ? true : false
		}
	];

	const contributorState = [
		{ name: SOLO,
		  icon: '🕺', 
		  id: uuidv4(),
		  class: initialFavorite.type === SOLO ? active : notActive,
		  selected: initialFavorite.type === SOLO ? true : false
		}, 
		{ name: MULTIPLE,
		  icon: '👯',
		  id: uuidv4(),
		  class: initialFavorite.type === MULTIPLE ? active : notActive,
		  selected: initialFavorite.type === MULTIPLE ? true : false
		},
	];

	const typeReducer = (state = [], action) => {
		if (action.type === TOGGLE) {
			return state.map(btn => {
				if (btn.id === action.payload.id) {
					return { ...btn, class: active, selected: true }
				}
				return { ...btn, class: notActive, selected: false }
			})
		}
	}

	const contributorReducer = (state = [], action) => {
		if (action.type === TOGGLE) {
			return state.map(btn => {
				if (btn.id === action.payload.id) {
					return { ...btn, class: active, selected: true }
				}
				return { ...btn, class: notActive, selected: false }
			})
		}
	}

	const [payload, setPayload] = useState(ProjectObject === {} || editing === false ? initialFavorite : ProjectObject);
	const [types, dispatch] = useReducer(typeReducer, typeState);
	const [contribs, dsptch] = useReducer(contributorReducer, contributorState);

	useEffect(() => {
		if (image && image.data && image.data.imageUpload && uploadcomplete === false) {
			setLocation(image.data.imageUpload.location)
			setUploadcomplete(true)
		} 
	}, [image, location, uploadcomplete])

	useEffect(() => {
		if (location !== '' && payload && payload.imageUrl === '') {
			setPayload({...payload, imageUrl: location})
		}
	}, [location, payload])


	const onChange = event => {
		if ( event.target.name !== 'points' ) {
			setPayload({...payload, [event.target.name]: event.target.value })
		} else if ( event.target.name === 'points' && typeof(Number(event.target.value)) === 'number' ) {
			setPayload({...payload, [event.target.name]: Number(event.target.value)})
		}
	};

	const select = (event) => {
		const id = event.target.id;
		
		if (event.target.name === "contributors") {
			dsptch({ type: TOGGLE, payload: { id } });
			setPayload({...payload, contributors: event.target.value});
		} else {
			dispatch({ type: TOGGLE, payload: { id } });
			setPayload({...payload, type: event.target.value})
		}
	};

	const handleUpload = ({ target }) => {
		const file = target.files[0]
		const validity = target.validity
		if (validity.valid) addImage({ variables: { file } });
	}

	return (
		<div className="w-min-0 flex flex-col justify-center mt-1">
			<div className="flex justify-between items-center">
				<div className="flex my-4">
					{
						types.map(g => (
							<button key={g.id} id={g.id} name="types" value={g.name} className={g.class} onClick={event => select(event) }>{g.icon}</button>
						))
					}
				</div>
				{error 
					? <p className={err}>Error</p>
					: <></>
				}
				<div className="flex my-4">
					{
						contribs.map(g => (
							<button key={g.id} id={g.id} name="contributors" value={g.name} className={g.class} onClick={event => select(event) }>{g.icon}</button>
						))
					}
				</div>
			</div>
			<form className="flex flex-col">
				<div className="flex flex-col lg:flex-row">
					<input 
						className="lg:w-1/2 text-left lg:mr-2 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="name"
						placeholder="name"
						value={payload.name}
						onChange={event => onChange(event)}/>
					<input 
						className="lg:w-1/2 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="slug"
						placeholder="slug"
						value={payload.slug}
						onChange={event => onChange(event)}/>
				</div>
				<div className="flex flex-col lg:flex-row lg:my-2 my-1">
					<input 
						className="lg:w-11/12 text-left lg:mr-2 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100"
						type="text"
						name="url"
						placeholder="url"
						value={payload.url}
						onChange={event => onChange(event)}/>
					<input 
						className="lg:w-24 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="number"
						name="points"
						placeholder="points"
						value={payload.points}
						onChange={event => onChange(event)}/>
				</div>
				<div>
					<input 
						className="lg:w-11/12 text-left lg:mr-6 px-2 py-2 mb-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100"
						type="file"
						accept="image/*"
						onChange={handleUpload}
					/>
					{ uploadcomplete ? <>✅</> : <></>}
				</div>
				<textarea 
					className="text-left mb-4 px-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded py-2 lg:my-0 my-2 focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
					type="text"
					name="content"
					placeholder="tldr"
					value={payload.content}
					onChange={event => onChange(event)}/>
				<button 
					className="bg-yellow-400 rounded text-white md:px-2 md:text-sm px-3 py-3 my-4 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50" 
					onClick={(event) => onSubmit(event, payload)}>
					{editing
						? "Update Project"
						: "Add Project"
					}
				</button>
			</form>
		</div>

	);
}

export default ProjectForm;