import React from "react";

const PostFilter = {
	ALL: 'ALL',
	HUMOR: 'HUMOR',
	OPINION: 'OPINION',
	EDUCATIONAL: 'EDUCATIONAL'
}

const Filter = ({ current, filter, refetch }) => {

	const filterAndFetch = async (input) => {
		if (current !== input) {
			await filter(input)
			refetch()
		}

	}

	return (
		<>
		<i className="fas fa-filter"></i>
		<div className="absolute lg:left-36 left-10 top-36 flex flex-col bg-white z-50">
			<div
				onClick={() => filterAndFetch(PostFilter.ALL)} 
				className="mb-px hover:mb-0 rounded hover:bg-gray-100 px-2">
				<button
				className="text-5xl font-semibold py-1 text-purple-800">
					all thoughts
				</button>
			</div>
			<div
				onClick={() => filterAndFetch(PostFilter.HUMOR)}  
				className="mb-px hover:mb-0 rounded hover:bg-gray-100 px-2">
				<button
				className="text-5xl font-semibold py-1 text-purple-800">
					humor
				</button>
			</div>
			<div
				onClick={() => filterAndFetch(PostFilter.OPINION)}  
				className="mb-px hover:mb-0 rounded hover:bg-gray-100 px-2">
				<button
				className="text-5xl font-semibold py-1 text-purple-800">
					opinion
				</button>
			</div>
			<div
				onClick={() => filterAndFetch(PostFilter.EDUCATIONAL)}  
				className="mb-px hover:mb-0 rounded hover:bg-gray-100 px-2">
				<button
				className="text-5xl font-semibold py-1 text-purple-800">
					educational
				</button>
			</div>
		</div>
		</>
	);
}

export default Filter;