import React, { useReducer, createContext } from "react";
import { v4 as uuidv4 } from "uuid";

export const NavContext = createContext();

const UNLOCK = "UNLOCK";
const RESET = "RESET";

const initialState = [
	{ 'path': '/about', 'locked': false, 'points': 0, 'id': uuidv4() }, 
	{ 'path': '/thoughts', 'locked': false, 'points': 0, 'id': uuidv4() }, 
	{ 'path': '/projects', 'locked': false, 'points': 0, 'id': uuidv4() }, 
	{ 'path': '/favorites', 'locked': false, 'points': 0, 'id': uuidv4() }, 
	{ 'path': '/quiz', 'locked': true, 'points': 8, 'id': uuidv4() }
];

const reducer = (state = [], action) => {
	if (action.type === UNLOCK) {
		return state.map(link => {
			if (link.id === action.payload.id) {
				return { ...link, points: 0, locked: false }
			}
			return link;
		})
	} else if (action.type === RESET) {
		return initialState;
	}
	return state;
};

export const NavProvider = ({ children }) => {
	const [links, dispatch] = useReducer(reducer, initialState);

	const unlock = ({ id }) => {
		dispatch({
			type: UNLOCK,
			payload: { id }
		})
	}

	const navReset = () => {
		dispatch({
			type: RESET
		})
	}

	return (
		<NavContext.Provider value={{ links, unlock, navReset }}>
			{ children }
		</NavContext.Provider>
	);
}