import React from "react";
import { Link } from "react-router-dom";

const Home = () => {

	return (
		<section className="fixed right-20 xl:top-40 lg:top-32 w-min-full h-screen flex z-0">
			<div id="speech-bubble" className="relative">
				<p className="absolute left-10 -top-4 md:-top-6 text-sm md:text-xl lg:text-2xl">Hello, I'm Taylor.</p>
				<p className="absolute right-9 md:right-12 lg:right-14 top-4 md:top-5 lg:top-6 Roboto text-sm md:text-xl lg:text-2xl font-black text-yellow-500 z-1">
					<Link to="/favorites">
					favorites
					</Link>
				</p>
				<p className="absolute right-9 md:right-12 lg:right-14 top-8 md:top-10 lg:top-12 Roboto text-sm md:text-xl lg:text-2xl font-black text-red-500 z-2">
					<Link to="/projects">
					projects
					</Link>
				</p>
				<div>
					<p className="Roboto ml-10 text-sm min-w-full md:text-xl lg:text-2xl">I park my 
						<span className="font-black text-purple-500 z-3">
							<Link className="pl-1" to="/thoughts">
								thoughts
							</Link>
						</span> here.
					</p>
				</div>
			</div>
			<div className="z-0">
				<img className="absolute left-0 bottom-44 sm:bottom-20 max-h-96 max-w-96 z-0" src="me-5.png" alt="pixelated mid 30's white man" />
			</div>
		</section>
	);
}

export default Home;