import React from "react";
import Link from "./Link";
import MultiplePeople from "./MultiplePeople";
import SinglePerson from "./SinglePerson";
import WebIcon from "./WebIcon";
import PhoneIcon from "./PhoneIcon";

const uri = process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_API_URI}` : 'http://localhost:4001';

const ProjectCell = ({ obj }) => {

	return (
		<div key={obj.id} className="group relative bg-contain h-48 hover:shadow-lg"
					style={{backgroundImage: `url(${uri+obj.imageUrl})`}}
					alt={obj.slug}
				>
			<div className="h-full w-full hover:bg-black hover:opacity-50 ">
					<a href={obj.url}>
						<h1 
							className="absolute top-1/3 left-4 text-sm md:text-base text-center font-black text-transparent group-hover:text-white hover:underline">
							<Link />
							<span className="ml-1">{obj.name}</span>
							</h1>
					</a>
					<div id="svg-group">
						{obj.contributors === "MULTIPLE"
						? 
							<MultiplePeople />
						:
							<SinglePerson />
						}
						{obj.type === "WEB"
						?
							<WebIcon />
						:
							<PhoneIcon />
						}
					</div>
				</div>
		</div>
	)
}

export default ProjectCell;