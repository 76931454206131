import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { AuthProvider } from "./context/AuthContext";
import { KarmaProvider } from "./context/KarmaContext";
import { NavProvider } from "./context/NavContext";
import { ViewedContentProvider } from "./context/ViewedContentContext";
import { QuizProvider } from "./context/QuizContext";
import client from './client';
import Nav from './components/Nav';
import App from './components/App';
import PointBoard from './components/PointBoard';
import Footer from './components/Footer';
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <KarmaProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <NavProvider>
              <ViewedContentProvider>
                <PointBoard />
            		<div className="w-full max-w-8xl mx-auto flex flex-col sm:flex-row">
                  <Nav />
                  <div id="content-wrapper" className="min-w-0 w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
                    <div className="w-full flex">
                      <QuizProvider>
            			      <App />
                        <Footer/>
                      </QuizProvider>
                    </div>
                  </div>
            		</div>
              </ViewedContentProvider>
            </NavProvider>
          </BrowserRouter>
        </ApolloProvider>
      </KarmaProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
