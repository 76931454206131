import gql from "graphql-tag";

import { FAVORITE_FIELDS, POST_CONNECTION, POST_CONTENT } from "./fields";

export const GET_FAVORITES = gql`
	query FavoriteList {
		favorites(input: {}) {
			...FavFields
		}
	}
	${FAVORITE_FIELDS}
`;

export const GET_POST_CONNECTIONS = gql`
	query postList($first: Int, $after: String, $last: Int, $before: String, $filter: PostFilter) {
		posts(first: $first, after: $after, last: $last, before: $before, filter: $filter) {
			...ConnectionFields
		}
	}
	${POST_CONNECTION}
`;

export const GET_CONTENT = gql`
	query post($slug: String) {
		post(input: $slug) {
			...Content
		}
	}
	${POST_CONTENT}
`;

export const GET_TOTAL = gql`
	query Total {
		count
	}
`
