import React, { useContext, useState, useEffect } from "react";
import LoadingBlogCell from "../components/LoadingBlogCell";
import BlogCell from "../components/BlogCell";
import ViewedBlogCell from "../components/ViewedBlogCell";
import BlogForm from "../components/BlogForm";
import Modal from "../components/Modal";
import FilterButton from "../components/FilterButton";
import FilterModal from "../components/FilterModal";
import Filter from "../components/Filter";
import PaginationButton from "../components/PaginationButton";

// MARK: -- Context
import { ViewedContentContext } from "../context/ViewedContentContext";
import { AuthContext } from "../context/AuthContext";

// MARK: -- graphql
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_POST_CONNECTIONS } from "../graphql/queries";
import { CREATE_POST, DELETE_POST } from "../graphql/mutations";

import { NetworkStatus } from "@apollo/client";

// MARK: -- schema
import schema from "../validations/BlogSchema";

const ADD = 'Add Post';

const NUMBER = 7;

// const updateQuery = (previousResult, { fetchMoreResult }) => {
// 	return fetchMoreResult.posts.edges.length ? fetchMoreResult : previousResult;
// };

const updateQuery = (previousResult, { fetchMoreResult }) => {
	return {
		posts: {
			__typename: fetchMoreResult.posts.__typename,
			edges: [...previousResult.posts.edges, ...fetchMoreResult.posts.edges],
			pageInfo: fetchMoreResult.posts.pageInfo,
		}
	}
};

const BlogList = () => {

	const { viewedPosts, view } = useContext(ViewedContentContext);

	const { isAuthenticated } = useContext(AuthContext);

	// MARK: -- Filtering
	const [filter, setFilter] = useState('ALL')
	const [filtering, setFiltering] = useState(false)

	// MARK: -- Modal for adding or editing
	const [err, setErr] = useState(false);
	const [show, setShow] = useState(false);
	const [editing, setEditing] = useState(false);

	// MARK: -- Graphql queries and mutations
	const { data, loading, error, refetch, networkStatus, fetchMore } = useQuery(GET_POST_CONNECTIONS, {
		variables: { first: NUMBER, after: null, last: null, before: null, filter: filter },
		notifyOnNetworkStatusChange: true,
	});

	const [addPost, newPost] = useMutation(CREATE_POST, {
		update(cache, { data: { addPost } }) {
			const data = cache.readQuery({ query: GET_POST_CONNECTIONS });
			cache.writeQuery({
				query: GET_POST_CONNECTIONS,
				data: [addPost, ...data.posts]
			});
		}
	});

	const [removePost, deleted] = useMutation(DELETE_POST, {
		update(cache, { data: { removePost }}) {
			const identifier = removePost.split(" ")[1];
			const data = cache.readQuery({ query: GET_POST_CONNECTIONS });
			cache.writeQuery({
				query: GET_POST_CONNECTIONS,
				data: data.posts.filter(obj => obj.id !== identifier)
			});
		}
	});

	const onSubmit = async (event, input) => {
		event.preventDefault();
		const valid = await schema.isValid(input);

		if (valid) {
			addPost({ 
				variables: { newPost: input },
				optimisticResponse: {
					__typename: 'Mutation',
					addPost: {
						__typename: 'Post',
						id: Math.floor(Math.random() * 1000) + '',
						title: input.title,
						type: input.type,
						slug: input.slug,
						points: input.points,
						locked: input.locked
					}
				}
			});
			setShow(false);
		}

		setErr(true)
		setTimeout(function() {
			setErr(false)
		}, 3000);

	}

	const onDelete = (input) => {
		const { id } = input;
		removePost({
			variables: { deleted: id }
		})
	}

	// MARK: -- Modals
	const showModal = (event) => {
		event.preventDefault();
		const text = event.target.innerText
		if (text === ADD) {
			setEditing(false);
		}
		setShow(true);
	}

	const hideModal = (event) => {
		event.preventDefault();
		const exitArea = Array.from(event.target.classList)[0]
		if (exitArea === 'close') {
			setShow(false);
		}
	}

	const showFilter = (event) => {
		document.body.classList.add('overflow-y-hidden')
		event.preventDefault();
		setFiltering(true)
	}

	const hideFilter = (event) => {
		document.body.classList.remove('overflow-y-hidden')
		event.preventDefault();
		setFiltering(false)
	}

	// MARK: -- Pagination
	const next = () => {
		sessionStorage.setItem('scrollY', window.pageYOffset)
		if (data.posts.pageInfo.endCursor) {
			fetchMore({
				variables:
				{
					first: NUMBER, 
					after: data.posts.pageInfo.endCursor, 
					last: null, 
					before: null, 
					filter: filter 
				},
				updateQuery
			});
		}
	}

	useEffect(() => {
		const pos = sessionStorage.getItem('scrollY')
		console.log(pos)
		if (pos && data !== null) {
			window.scrollTo(0, pos)
			setTimeout(() => { sessionStorage.removeItem('scrollY') }, 3000)
		}
	}, [data])


	if (networkStatus === NetworkStatus.refetch) return <p>Refetching!</p>
	if (loading || data === undefined || data.posts[0] === null) 
		return (
		<div>
			<FilterButton filter={filter} />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-40" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-48" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-44" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-44" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-44" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-44" />
			<LoadingBlogCell className="h-4 bg-gray-100 rounded w-44" />
		</div>
	)
	if (error || newPost.error || deleted.error) return <p>ERROR</p>
	if (data)
		return (
		<section className="overflow-y-auto">
		{ 
			isAuthenticated()
			?	<>
					<button
					type="button" 
					className="rounded border border-b-4 border-green-900 bg-green-600 px-2 py-1 mb-4 text-white font-medium hover:bg-green-700"
					onClick={(e) => showModal(e)}>
						{ADD}
					</button>
					{
						show
						?
						<Modal show={show} handleClose={(e) => hideModal(e)}>
							 <BlogForm editing={editing} onSubmit={onSubmit} error={err} />
						</Modal>
						: 
						<></>
					}
				</>
			: <></>
		}
		<FilterButton showFilter={showFilter} filter={filter} />
		{
			filtering
			?
			<FilterModal show={filtering} handleClose={(e) => hideFilter(e)}>
				<Filter current={filter} filter={setFilter} refetch={refetch} />
			</FilterModal>
			:
			<></>
		}
		<div>
			{data.posts.edges.map((obj, index) => (
				viewedPosts.includes(obj.node.id)
					? <ViewedBlogCell 
						key={obj.cursor} 
						obj={obj.node} 
						auth={isAuthenticated} 
						onDelete={onDelete} 
						index={index} 
						number={NUMBER}
						last={data.posts.edges.length}
					/>
					: <BlogCell 
						key={obj.cursor} 
						obj={obj.node} 
						view={view} 
						auth={isAuthenticated} 
						onDelete={onDelete} 
						index={index} 
						number={NUMBER}
						last={data.posts.edges.length}
					/>
			))}
			{data.posts.pageInfo.hasNextPage 
				?
				<PaginationButton title={'load more'} action={next} />
				:
				<PaginationButton title={"You've reached the bottom"} disabled={true} />
			}
		</div>
		</section>
	);
	
}

export default BlogList;