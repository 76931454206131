import React from "react";

const FilterModal = ({ handleClose, show, children }) => {
	const showHideClassName = show ? "close fixed left-0 top-0 h-full w-full display-block bg-white opacity-100 flex justify-center z-50" : "hidden"
	return (
		<div className={showHideClassName} onClick={handleClose}>
			<section className="fixed lg:mt-10 md:w-4/5 w-full bg-white p-4">
				<button className="close absolute right-3 top-1" type="button" onClick={handleClose}><i className="close fas fa-times"></i></button>
				{children}
			</section>
		</div>
	);
};

export default FilterModal;