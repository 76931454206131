import React from "react";

const PaginationButton = ({title, action=null, disabled=false}) => {
	return (
		<div className="flex justify-center mt-6">
		{disabled ?
			<button 
				className="ml-2 text-sm font-black text-gray-100 bg-gray-400 px-4 py-2 rounded opacity-50 disabled cursor-not-allowed">
					{title}
			</button>
			:
			<button onClick={action}
				className="ml-2 text-sm font-black text-white bg-green-500 px-4 py-2 rounded hover:bg-green-700">
				{title}
			</button>
		}
		</div>
	)
}

export default PaginationButton;