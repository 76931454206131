import React from "react";

const Input = ({ type, onChange, placeholder }) => {
	return (
		<input className="border ring-gray-200 ring-2 shadow-md rounded py-2 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" 
			placeholder={placeholder} 
			type={type} 
			onChange={onChange}
		/>
	);
}

export default Input;