import React, { useReducer, createContext } from "react";

export const KarmaContext = createContext();

const KARMA_ADD = "KARMA_ADD";
const KARMA_REMOVE = "KARMA_REMOVE";
const RESET = "RESET";

const reducer = (state = 0, action) => {
	if (action.type === KARMA_ADD) {
		return state = state + action.payload.points
	}
	else if (action.type === KARMA_REMOVE) {
		return state = state - action.payload.points
	}
	else if (action.type === RESET) {
		return 0
	}
	return state;
}

export const KarmaProvider = ({ children }) => {
	const [karma, dispatch] = useReducer(reducer, 0);

	const addKarma = ({ points }) => {
		dispatch({
			type: KARMA_ADD,
			payload: { points }
		});
	};

	const removeKarma = ({ points }) => {
		dispatch({
			type: KARMA_REMOVE,
			payload: { points }
		});
	};

	const karmaReset = () => {
		dispatch({
			type: RESET
		});
	}

	const giveStarHandler = (elementId, elementId2, points) => {
		const star = document.getElementById(elementId);
		const starPoint = document.getElementById(elementId2);
		if (star && starPoint) {
			star.classList.add("animate-spin-quick");
			starPoint.classList.add("animate-ping")
			setTimeout(function() {
				star.classList.remove("animate-spin-quick");
				starPoint.classList.remove("animate-ping")
			}, 1000);
		}
		addKarma({ points });
	}

	return (
		<KarmaContext.Provider value={{ karma, giveStarHandler, removeKarma, karmaReset }}>
			{children}
		</KarmaContext.Provider>
	); 
};