import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Star from "./Star";
import { KarmaContext } from "../context/KarmaContext";
import { AuthContext } from "../context/AuthContext";

import { Link } from "react-router-dom";

const PointBoard = () => {
	const location = useLocation()
	const { karma } = useContext(KarmaContext);
	const { isAuthenticated } = useContext(AuthContext);

	return (
		<div className="sticky top-0 z-40 lg:z-50 text-sm w-full max-w-8xl mx-auto flex-none flex h-16 border-white bg-white">
				<div className="min-w-full flex justify-between items-center">
					<div className="flex ml-4">
						<Star id="star" />
						<code className="inline ml-2"><Link className="font-bold hover:text-indigo-300 cursor-pointer" to="/">home</Link>
							<span className="hidden sm:inline">/taylor</span>{location.pathname} on  
							{
								isAuthenticated() 
								? <span className="text-purple-800"> <i className="fas fa-code-branch"></i> root </span>
								: <span className="text-purple-800"> <i className="fas fa-code-branch"></i> read-only </span>
							} 
							<span className="text-red-800">
							{
								isAuthenticated() 
								? <>[x+!]</> 
								: <></>
							}
							</span> 
							<span className="hidden md:inline"> is 
							{
								isAuthenticated()
								? <span className="hidden md:inline"> 🗃</span>
								: <span className="hidden md:inline"> 📦</span>
							}<span className="sm:inline md:hidden lg:hidden xl:hidden">📱</span> v1.0.0</span>
						</code>
					</div>
					<div className="flex items-center">
						<Star id="star-points" />
						<p className="uppercase text-yellow-300 font-sans text-sm font-extrabold mr-8 ml-3">{karma} <span className="hidden sm:inline">stars</span></p>
					</div>
				</div>
		</div>
	);
};

export default PointBoard;