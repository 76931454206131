import React from "react";

const Link = () => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		viewBox="0 0 24 24"  
		fill="none" 
		stroke="currentColor"
		className="h-6 w-6 inline text-transparent group-hover:text-white">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
	</svg>
);

export default Link;