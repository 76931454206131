import React from "react";

const Chevron = ({ isOpen, className }) => {
	return (
		<span>{
			isOpen ?
			<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 20">
		  		<path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
			</svg>
			:
			<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 20">
	 		 <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
			</svg>
		}
		</span>
	)
}

export default Chevron;