import React from "react";
import Locked from "./Locked";
import { Link , useRouteMatch } from "react-router-dom";

const matched = "bg-gray-100 text-indigo-800 font-bold py-2 px-6 cursor-pointer flex sm:flex-row flex-col items-center";
const notMatched = "bg-white hover:text-indigo-300 text-black font-bold py-2 px-5 cursor-pointer flex sm:flex-row flex-col items-center"; 

const NavLink = ({ label, to, active, locked, points }) => {

	const match = useRouteMatch({
		path: to,
		exact: active
	});

	const emoji = (to) => {
		switch (to) {
			case "about":
				return "ℹ️"
			case "thoughts":
				return "💭"
			case "projects":
				return "🛠";
			case "favorites":
				return "❤️"
			case "quiz":
				return "❔"
			default:
				console.error("exhausted: should never reach this")
		}
	}


	
	return (
		<Link to={to} 
			  className={match 
			  ? matched
			  : notMatched}
		>	
			<span className="sm:hidden inline">{emoji(label)}</span>
			<span className="sm:pl-1 xs:inline hidden">{label}</span>
			{locked 
				? <Locked className="sm:h-5 sm:w-5 h-3 w-3 inline" />
				: <></>
			}
		</Link>
	);
};

export default NavLink;