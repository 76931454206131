import * as yup from "yup";

const schema =  yup.object().shape({
	type: yup.string().min(2).max(255).required(),
	title: yup.string().min(2).max(255).required(),
	slug: yup.string().min(2).max(255).required(),
	points: yup.number().required().positive().integer(),
	content: yup.string().min(2).max(255).required(),
	imageUrl: yup.string().min(2).max(255).required(),
	locked: yup.boolean().required()
});

export default schema;