import gql from "graphql-tag";

import { FAVORITE_FIELDS, POST_FIELDS, POST_CONTENT } from "./fields";

export const CREATE_FAVORITE = gql`
	mutation AddFavorite($newFavorite: NewFavoriteInput!) {
		addFavorite(input: $newFavorite) {
			...FavFields
		}
	}
	${FAVORITE_FIELDS}
`;

export const UPDATE_FAVORITE = gql`
	mutation UpdateFavorite($updatedFavorite: FavoriteInput!, $id: ID!) {
		updateFavorite(input: $updatedFavorite, id: $id) {
			...FavFields
		}
	}
	${FAVORITE_FIELDS}
`;

export const DELETE_FAVORITE = gql`
	mutation DeleteFavorite($deleted: ID!) {
		removeFavorite(id: $deleted)
	}
`

export const CREATE_POST = gql`
	mutation CreatePost($newPost: NewPostInput!) {
		addPost(input: $newPost) {
			...PostFields
		}
	}
	${POST_FIELDS}
`;

export const DELETE_POST = gql`
	mutation DeletePost($deleted: ID!) {
			removePost(id: $deleted)
		}
`;

export const UPDATE_POST = gql`
	mutation UpdatePost($updatedPost: PostInput!, $id: ID!) {
		updatePost(input: $updatedPost, id: $id) {
			...Content
		}
	}
	${POST_CONTENT}
`;