import React, { useState, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

const active = "active rounded px-4 py-1 mr-2 bg-purple-400";
const notActive = "not-active rounded ring-1 px-4 py-1 mr-2 ring-gray-200";

const err = "w-full h-8 pl-4 pt-1 bg-red-500 text-white font-black rounded"

const FavoriteForm = ({ editing, favoriteObject={}, onSubmit, error }) => {

	const initialFavorite =
		{
			type: favoriteObject.type || '',
			title: favoriteObject.title || '',
			slug: favoriteObject.slug || '',
			url: favoriteObject.url || '',
			points: favoriteObject.points || 0,
			content: favoriteObject.content || '',
			locked: favoriteObject.locked || false
		};

	const initialState = [
		{ name: 'FILM',
		  icon: '🎥',
		  id: uuidv4(),
		  class: initialFavorite.type === 'FILM' ? active : notActive,
		  selected: initialFavorite.type === 'FILM' ? true : false
		}, 
		{ name: 'BOOK',
		  icon: '📚', 
		  id: uuidv4(),
		  class: initialFavorite.type === 'BOOK' ? active : notActive,
		  selected: initialFavorite.type === 'BOOK' ? true : false
		}, 
		{ name: 'ARTICLE',
		  icon: '📰',
		  id: uuidv4(),
		  class: initialFavorite.type === 'ARTICLE' ? active : notActive,
		  selected: initialFavorite.type === 'ARTICLE' ? true : false
		}
	];

	const TOGGLE = 'TOGGLE';

	const reducer = (state = [], action) => {
		if (action.type === TOGGLE) {
			return state.map(btn => {
				if (btn.id === action.payload.id) {
					return { ...btn, class: active, selected: true }
				}
				return { ...btn, class: notActive, selected: false }
			})
		}
	}

	const [payload, setPayload] = useState(favoriteObject === {} || editing === false ? initialFavorite : favoriteObject);
	const [genre, dispatch] = useReducer(reducer, initialState);

	const onChange = event => {
		if ( event.target.name !== 'points' ) {
			setPayload({...payload, [event.target.name]: event.target.value })
		} else if ( event.target.name === 'points' && typeof(Number(event.target.value)) === 'number' ) {
			setPayload({...payload, [event.target.name]: Number(event.target.value)})
		}
	}

	const select = (event) => {
		const id = event.target.id;

		dispatch({
			type: TOGGLE,
			payload: { id }
		});

		switch (event.target.value) {
			case "FILM":
				setPayload({...payload, type: event.target.value})
				break
			case "BOOK":
				setPayload({...payload, type: event.target.value})
				break
			case "ARTICLE":
				setPayload({...payload, type: event.target.value})
				break
			default:
				break 
		}
	}; 

	return (
		<div className="w-min-0 flex flex-col justify-center mt-1">
			<div className="flex justify-between items-center">
				<div className="flex my-4">
					{
						genre.map(g => (
							<button key={g.id} id={g.id} value={g.name} className={g.class} onClick={event => select(event) }>{g.icon}</button>
						))
					}
				</div>
				{error 
					? <p className={err}>Error</p>
					: <></>
				}
			</div>
			<form className="flex flex-col">
				<div className="flex flex-col lg:flex-row">
					<input 
						className="lg:w-1/2 text-left lg:mr-6 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="title"
						placeholder="title"
						value={payload.title}
						onChange={event => onChange(event)}/>
					<input 
						className="lg:w-1/2 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="slug"
						placeholder="slug"
						value={payload.slug}
						onChange={event => onChange(event)}/>
				</div>
				<div className="flex flex-col lg:flex-row lg:my-4 my-1">
					<input 
						className="lg:w-11/12 text-left lg:mr-6 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100"
						type="text"
						name="url"
						placeholder="url"
						value={payload.url}
						onChange={event => onChange(event)}/>
					<input 
						className="lg:w-24 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="number"
						name="points"
						placeholder="points"
						value={payload.points}
						onChange={event => onChange(event)}/>
				</div>
				<textarea 
					className="text-left mb-4 px-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded py-2 lg:my-0 my-2 focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
					type="text"
					name="content"
					placeholder="tldr"
					value={payload.content}
					onChange={event => onChange(event)}/>
				<button 
					className="bg-yellow-400 rounded text-white md:px-2 md:text-sm px-3 py-3 my-4 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50" 
					onClick={() => onSubmit(payload)}>
					{editing
						? "Update Favorite"
						: "Add Favorite"
					}
				</button>
			</form>
		</div>

	);
}

export default FavoriteForm;