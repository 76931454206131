import React, { useState, useReducer } from "react";
import Locked from "../components/Locked";
import Unlocked from "../components/Unlocked";
import { v4 as uuidv4 } from "uuid";

const err = "px-2 bg-red-500 text-white font-black text-sm rounded"

const BlogForm = ({ onSubmit, editing=false, BlogObject={}, error }) => {

	const initialPost = {
		type: BlogObject.type || '' ,
		title: BlogObject.title || '',
		slug: BlogObject.slug || '',
		points: BlogObject.points || 0,
		content: BlogObject.content || '',
		imageUrl: BlogObject.imageUrl || '',
		locked: BlogObject.locked || false
	};

	const [payload, setPayload] = useState(initialPost);

	const [textAreaCount, setTextAreaCount] = useState(initialPost.content.length || 0);

	const active = "active inline rounded px-4 mr-2 bg-purple-400"
	const notActive = "not-active inline rounded ring-1 px-4 mr-2 ring-gray-200"

	const initialState = [
		{ name: 'HUMOR',
		  icon: '🤣 lol',
		  id: uuidv4(),
		  class: initialPost.type === 'HUMOR' 
		  		? active
		  		: notActive,
		  selected: initialPost.type === 'HUMOR' ? true : false, 
		}, 
		{ name: 'OPINION',
		  icon: '🙄 op', 
		  id: uuidv4(),
		  class: initialPost.type === 'OPINION' 
		  		? active
		  		: notActive,
		  selected: initialPost.type === 'OPINION' ? true : false, 
		}, 
		{ name: 'EDUCATIONAL',
		  icon: '🧑‍🏫 ed',
		  id: uuidv4(),
		  class: initialPost.type === 'EDUCATIONAL' 
		  		? active
		  		: notActive,
		  selected: initialPost.type === 'EDUCATIONAL' ? true : false, 
		}
	];

	const TOGGLE = 'TOGGLE';

	const reducer = (state = [], action) => {
		if (action.type === TOGGLE) {
			return state.map(btn => {
				if (btn.id === action.payload.id) {
					return { ...btn, class: active, selected: true }
				}
				return { ...btn, class: notActive, selected: false }
			})
		}
	}

	const [genre, dispatch] = useReducer(reducer, initialState);

	const onChange = event => {
		if ( event.target.name !== 'points' ) {
			setPayload({...payload, [event.target.name]: event.target.value })
		} else if ( event.target.name === 'points' && typeof(Number(event.target.value)) === 'number' ) {
			setPayload({...payload, [event.target.name]: Number(event.target.value)})
		}
	}

	const tog = () => {
		setPayload({...payload, locked: !payload.locked})
	}

	const select = (event) => {

		const id = event.target.id;

		dispatch({
			type: TOGGLE,
			payload: { id }
		});

		switch (event.target.value) {
			case "HUMOR":
				setPayload({...payload, type: event.target.value})
				break
			case "OPINION":
				setPayload({...payload, type: event.target.value})
				break
			case "EDUCATIONAL":
				setPayload({...payload, type: event.target.value})
				break
			default:
				break
		}
	};

	return (
		<div className="relative w-min-0 flex flex-col justify-center">
			<div className="flex justify-between my-4 inline">
				<div>
					{
						genre.map(g => (
							<button key={g.id} id={g.id} value={g.name} className={g.class} onClick={event => select(event) }>{g.icon}</button>
						))
					}
					<button name="locked" onClick={() => tog()}>{payload.locked ? <Locked className="h-5 w-5 inline" /> : <Unlocked /> }</button>
				</div>
				{error 
					? <p className={err}>Error</p>
					: <></>
				}
				<div>
					{
						editing
						? <p className="rounded px-4 bg-red-400 font-black">Update</p>
						: <p className="rounded px-4 bg-green-400 font-black">New</p>
					}
				</div>
			</div>
			<form className="flex flex-col">
				<div className="flex flex-col lg:flex-row">
					<input 
						className="lg:w-1/2 text-left lg:mr-6 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="title"
						placeholder="title"
						value={payload.title}
						onChange={event => onChange(event)} />
					<input 
						className="lg:w-1/2 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="text"
						name="slug"
						placeholder="slug"
						value={payload.slug}
						onChange={event => onChange(event)} />
				</div>
				<div className="flex flex-col lg:flex-row lg:my-4 my-1">
					<input 
						className="lg:w-11/12 text-left lg:mr-6 px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100"
						type="text"
						name="imageUrl"
						placeholder="image url"
						value={payload.imageUrl}
						onChange={event => onChange(event)} />
					<input 
						className="lg:w-24 text-left px-2 py-2 lg:my-0 my-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
						type="number"
						name="points"
						placeholder="points"
						value={payload.points}
						onChange={event => onChange(event)} />
				</div>
				<textarea
					className="text-left mb-4 px-2 border ring-gray-200 ring-1 focus:ring-4 focus:ring-purple-400 focus:shadow-md rounded py-2 lg:my-0 my-2 focus:outline-none focus:border-transparent focus:bg-white bg-gray-100" 
					type="text"
					name="content"
					placeholder="tldr"
					value={payload.content}
					onChange={event => {
						onChange(event)
						setTextAreaCount(event.target.value.length)
					}} />
				<p className="text-xs text-gray-400 font-bold py-2">{textAreaCount} / 255 characters</p>
				<button 
					className="bg-yellow-400 rounded text-white md:px-2 md:text-sm px-3 py-3 my-4 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-opacity-50" 
				type="submit"
				onClick={event => onSubmit(event, payload)}>
				{
					editing
					? <>Update Thought</>
					: <>Add Thought</>
				}
				</button>
			</form>
		</div>

	);
}

export default BlogForm;