import React from "react";

const Form = ({ submit, children }) => {

	return (
		<div className="w-min-0 h-48 flex justify-center items-center">
			<form onSubmit={submit} className="flex flex-col items-center">
				{ children }
			</form>
		</div>
	);
}

export default Form;