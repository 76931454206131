import React from "react";

const About = () => {
	return (
		<>
		<section className="flex flex-col justify-around w-min-0 mr-0 xl:mr-96">
			<p className="text-lg mb-2">Taylor Lohman</p>
			<p className="text-lg mb-2 max-w-prose">
				I work as a software developer (concentrated on the web) and I enjoy reading, watching films with the subtitles on, and eating almond croissants with coffee.<br/>
				I'm a big fan of Seinfeld and The Office.
			</p>
			<p className="text-lg mr-1 xl:mr-96">If you would like to contact me, my email is <code className="bg-gray-200 p-1">tclaws007@gmail.com</code></p>
		</section>
		<table className="mt-8 table-auto">
			<thead className="text-xs font-light">
				<tr>
					<th>Company</th>
					<th>Award</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Radio Shack<span className="text-red-800 font-light">*</span></td>
					<td className="px-2">30 Under 30, 2015</td>
					<td className="px-2 animate-pulse text-xs">pending<span className="animate-bounce">...</span></td>
				</tr>
				<tr>
					<td>Blockbuster<span className="text-red-800 font-light">*</span></td>
					<td className="px-2">3 Under 45, 2014</td>
					<td className="px-2 animate-pulse text-xs">pending...</td>
				</tr>
				<tr className="font-semibold text-white">
					<td className="bg-green-400 rounded-l">Circuit City<span className="text-red-800 font-light">*</span></td>
					<td className="bg-green-400 px-2">Last customer, 2009</td>
					<td className="bg-green-400 rounded-r px-2">!Verified <span className="text-green-600"><i className="fas fa-check-circle"></i></span></td>
				</tr>
				<tr>
					<td>Pets.com<span className="text-red-800 font-light">*</span></td>
					<td className="px-2">50 Under 20, 2000</td>
					<td className="px-2 animate-pulse text-xs">pending...</td>
				</tr>
			</tbody>
			</table>
			<p className="text-xs font-light text-gray-500"><span className="text-red-800">*</span>Defunct company</p>
		</>
	);
}

export default About;