import React from "react";

const PhoneIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" 
		fill="none" 
		viewBox="0 0 24 24" 
		stroke="currentColor"
		className="h-8 w-8 sm:h-6 sm:w-6 absolute bottom-0 right-0 text-transparent group-hover:text-white">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
	</svg>
);

export default PhoneIcon;