import React from "react";

const SinglePerson = () => (
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		viewBox="0 0 20 20" 
		fill="currentColor"
		className="h-8 w-8 sm:h-6 sm:w-6 absolute bottom-0 right-8 sm:right-6 text-transparent group-hover:text-white">
				<path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
	</svg>
);

export default SinglePerson;