import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { AuthContext } from "../context/AuthContext";

import ProjectCell from "../components/ProjectCell";
import Modal from "../components/Modal";
import ProjectForm from "../components/ProjectForm";

import schema from "../validations/ProjectSchema";

const ADD = 'Add Project';

const PROJECT_FIELDS = gql`
	fragment ProjectFields on Project {
		id
		name
		type
		slug
		imageUrl
		url
		contributors
		points
	}
`;

const GET_PROJECTS = gql`
	query projectsList {
		projects(input: {}) {
			...ProjectFields
		}
	}
	${PROJECT_FIELDS}
`;

const ADD_PROJECT = gql`
	mutation AddProject($newProject: NewProjectInput!) {
		addProject(input: $newProject) {
			...ProjectFields
		}
	}
	${PROJECT_FIELDS}
`;

const UPDATE_PROJECT = gql`
	mutation UpdateProject($updatedProject: ProjectInput!, $id: ID!) {
		updateProject(input: $updatedProject, id: $id) {
			...ProjectFields
		}
	}
	${PROJECT_FIELDS}
`;

const DELETE_PROJECT = gql`
	mutation RemoveProject($deleted: ID!) {
		removeProject(id: $deleted)
	}
`;

const Projects = () => {

	const { isAuthenticated } = useContext(AuthContext);

	const [err, setErr] = useState(false);
	const [show, setShow] = useState(false);
	const [editing, setEditing] = useState(false);

	const [addProject, newProject] = useMutation(ADD_PROJECT, {
		update(cache, { data: {addProject} }) {
			const data = cache.readQuery({ query: GET_PROJECTS });
			cache.writeQuery({
				query: GET_PROJECTS,
				data: { projects: [addProject, ...data.projects] }
			});
		}
	});

	const [updateProject, updated] = useMutation(UPDATE_PROJECT, {
		update(cache, { data: { updateProject }}) {
			const data = cache.readQuery({ query: GET_PROJECTS });
			cache.writeQuery({
				query: GET_PROJECTS,
				data: { projects: [...data.projects] }
			});
		}
	});

	const [removeProject, deleted] = useMutation(DELETE_PROJECT, {
		update(cache, { data: { removeProject }}) {
			const identifier = removeProject.split(" ")[1];
			const data = cache.readQuery({ data: GET_PROJECTS });
			cache.writeQuery({
				query: GET_PROJECTS,
				data: { projects: data.projects.filter(obj => obj.id !== identifier) }
			});
		}
	});

	const onSubmit = async (event, input) => {
		event.preventDefault();
		const valid = await schema.isValid(input);

		if (valid) {

			addProject({ 
				variables: { newProject: input },
				optimisticResponse: {
					__typename: 'Mutation',
					addProject: {
						__typename: 'Project',
						id: Math.floor(Math.random() * 1000) + '',
						name: input.title,
						type: input.type,
						slug: input.slug,
						imageUrl: input.imageUrl,
						url: input.url,
						contributors: input.contributors,
						points: input.points
					}
				}
			});
			setShow(false);
		}

		setErr(true)
		setTimeout(function() {
			setErr(false)
		}, 3000);

	}

	const onDelete = (input) => {
		const { id } = input;
		removeProject({
			variables: { deleted: id }
		})
	}

	const showModal = (event) => {
		event.preventDefault();
		const text = event.target.innerText
		if (text === ADD) {
			setEditing(false);
		}
		setShow(true);
	}

	const hideModal = (event) => {
		event.preventDefault();
		const exitArea = Array.from(event.target.classList)[0]
		if (exitArea === 'close') {
			setShow(false);
		}
	}

	const {data, loading, error} = useQuery(GET_PROJECTS);
	if (loading) return <p>Loading...</p>
	if (error) return <p>ERROR</p>
	if (data)

	return (
		<>
		{ 
			isAuthenticated()
			?	<>
					<button
					type="button" 
					className="rounded border border-b-4 border-green-900 bg-green-600 px-2 py-1 mb-4 text-white font-medium hover:bg-green-700"
					onClick={(e) => showModal(e)}>
						{ADD}
					</button>
					{
					show
					?
					<Modal show={show} handleClose={(e) => hideModal(e)}>
						 <ProjectForm 
						 	editing={editing} 
						 	onSubmit={onSubmit}
						 	error={err}
						/>
					</Modal>
					: 
					<></>
				}
				</>
			: <></>
		}
		<section className="grid 2xl:grids-col-6 xl:grids-col-5 lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-1 justify-items-stretch">
			{data.projects.map(obj => (
				<ProjectCell key={obj.id} obj={obj} />
			))}
		</section>
		</>
	);
}

export default Projects;