import React from "react";

const Button = ({ type, title }) => {
	return (
		<button className="bg-purple-600 rounded text-white px-3 py-2 mt-2 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50" 
			type={type}>
			{title}
		</button>
	);
}

export default Button;