import React from "react";

const FourOhFour = () => {
	return (
		<>
		<img className="max-h-96 max-w-96 z-0" src="404.png" alt="deadlink" />
		<p>I don't know how you got here but it's a dead link</p>
		</>
	);
}

export default FourOhFour;